<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiApi }}
      </v-icon>
      <span class="text-break">APIs</span>
    </v-card-title>
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            Quasr provides an <strong>Administration API (GraphQL)</strong> which you can use with your current <strong>personal access token</strong>, containing the scope <span class="code">{{ ADMIN_SCOPE }}</span>.<br /><br />
            Resources:
            <ul>
              <li>
                <a
                  href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#d522ab39-f24f-488b-b94b-ab427097fd5b"
                  target="_blank"
                >Postman Collection</a>
              </li>
              <li>
                <a
                  href="https://mathiasconradt.gitbook.io/quasr-alpha-preview/product-and-features/apis/admin-api-graphql"
                  target="_blank"
                >Admin API Docs</a>
              </li>
            </ul>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="loginStatus.access_token"
              label="Your Personal Access Token"
              outlined
              dense
              :append-icon="icons.mdiContentCopy"
              :readonly="true"
              @click:append="copyAccessTokenToClipboard"
            ></v-text-field>
            <strong>Do not share your personal access token with anyone.</strong><br />
            The token expires: {{ df(loginStatus.exp) }}<br />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiApi, mdiContentCopy,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import { ref } from '@vue/composition-api'

export default {
  props: {
    tenantData: {
      type: Object,
      default: () => {},
    },
    subscriptions: {
      type: Array[Object],
      default: () => [],
    },
    mode: { type: String, default: undefined },
  },
  data() {
    return {
      inputFieldRules: [
        value => !!value || 'Required',
      ],
    }
  },

  /**
   * methods
   */
  methods: {

    /**
     * copyAccessTokenToClipboard
     */
    copyAccessTokenToClipboard() {
      QuasrHelper.copyTextToClipboard(this.loginStatus.access_token)
    },

    /**
     * df
     */
    df(epoch) {
      return new Date(epoch * 1000).toLocaleString()
    },
  },

  /**
   * setup
   */
  setup() {
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const ADMIN_SCOPE = API_ENV === 'prod' ? 'https://api.quasr.io/scopes/admin' : `https://api-${API_ENV}.quasr.io/scopes/admin`
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const isCurrentPasswordVisible = ref(false)

    return {
      QUASR_ENV,
      ADMIN_SCOPE,
      loginStatus,
      isCurrentPasswordVisible,
      icons: {
        mdiApi,
        mdiContentCopy,
      },
    }
  },
}
</script>
